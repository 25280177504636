import * as React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import {Box, Container, Grid} from "@mui/material";
import {useEffect, useState} from "react";
import {getMyProjects, Project} from "../../services/ProjectService";
import ProjectGrid from "../Project/ProjectGrid";

function Copyright(props: any) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://mui.com/">
                RefMedium
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

function DashboardContent() {
    
    const [loading, setLoading] = useState(true)
    const [projects, setProjects] = useState<Project[]>([])
    
    useEffect(() => {
        populateProjects()
    }, [])
    
    async function populateProjects() {
        getMyProjects().then(result => {
            setProjects(result)
            setLoading(false)
        })
    }
    
    return (
        <Container>
            <Typography
                variant="h4"
                component="h2"
                align={"center"}
                fontWeight={700}
                fontSize={24}
                sx={{
                    marginBottom: 2,
                    marginTop: 6
            }}
            >
                Projects
            </Typography>
            
            <Grid container spacing={4} sx={{marginTop: 1}}>
                {projects.map(project => <ProjectGrid project={project}></ProjectGrid>)}
            </Grid>
        </Container>
    );
}

export default function Dashboard() {
    return <DashboardContent />
}
