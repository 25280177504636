import React, {useEffect, useState} from "react";
import {getAllProjects, Project} from "../../services/ProjectService";
import ProjectGrid from "./ProjectGrid";
import {Grid, Typography} from "@mui/material";

export default function ProjectList() {
    const [projects, setProjects] = useState<Project[]>([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getProjectData()
    }, [])

    async function getProjectData() {
        setLoading(true)

        const response = await getAllProjects()
        setProjects(response)
        setLoading(false)
    }

    return (
        <>
            <Typography
                variant="h4"
                component="h2"
                align={"left"}
                fontWeight={700}
                fontSize={24}
                sx={{marginBottom: 2}}
            >
                Projects
            </Typography>
            
            <Grid container spacing={4} sx={{marginTop: 1}}>
                {projects.map(project => <ProjectGrid project={project} key={project.id}></ProjectGrid>)}
            </Grid>
        </>
    )
}
