import React, {useEffect, useState} from "react";
import {getProjectById, Project} from "../../services/ProjectService";
import {
    Box,
    Breadcrumbs,
    CircularProgress,
    Container,
    Grid,
    Link, Paper,
    Tab,
    Typography
} from "@mui/material";
import {useParams} from "react-router-dom";
import { Copyright } from "@mui/icons-material";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import SearchIcon from "@mui/icons-material/Search";

export default function ViewProject() {

    const [loading, setLoading] = useState(false)
    const [project, setProject] = useState<Project>(null)

    const {id} = useParams()

    const [tab, setTab] = useState("1")

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setTab(newValue);
    };
    
    async function getProject() {
        getProjectById(id)
            .then(response => {
                setLoading(false)
                setProject(response as Project)
            })
            .catch(err => {
                setLoading(false)
            })
    }

    useEffect(() => {
        getProject()
    }, [id])

    if (loading) {
        return (
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <CircularProgress sx={{marginTop: 9}}/>
            </Box>
        );
    }
    
    if(project == null) {
        return (
            <Container
                sx={{
                    textAlign: "center",
                    marginTop: 8
                }}
            >

                <SearchIcon></SearchIcon>

                <Typography
                    variant={"body1"}
                    marginTop={3}
                >Sorry, we couldn't find that project!</Typography>
            </Container>
        )
    }
    
    return (
        <Container>
            <Breadcrumbs aria-label="breadcrumb" sx={{marginY: 2.5}}>
                <Link underline="hover" color="inherit" href="/">
                    pippin_mole
                </Link>
                <Link
                    underline="hover"
                    color="inherit"
                    href="/material-ui/getting-started/installation/"
                >
                    Projects
                </Link>
                <Typography color="text.primary">{project.projectName}</Typography>
            </Breadcrumbs>

            <TabContext value={tab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label="Info" value="1" />
                        <Tab label="References" value="2" />
                        <Tab label="Settings" value="3" />
                    </TabList>
                </Box>
                <TabPanel sx={{margin: 0, padding: 0}} value="1">
                    <DescriptionTab project={project} />
                </TabPanel>
                <TabPanel value="2">
                    <ImageTab project={project} />
                </TabPanel>
                <TabPanel value="3">
                    <SettingsTab />
                </TabPanel>
            </TabContext>
        </Container>
    )
}

interface DescriptionTabProps {
    project: Project
}

function DescriptionTab(props: DescriptionTabProps) {
    return (
        <Container maxWidth="xl" sx={{mt: 2, mb: 2}}>
            <Grid container spacing={3}>
                {/* Chart */}
                <Grid item xs={12} md={8} lg={9}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: 240,
                        }}
                    >
                        <Typography variant={"h5"} gutterBottom>Description</Typography>
                        
                        <Typography variant={"subtitle1"} sx={{whiteSpace: "pre"}}>
                            {props.project.description}
                        </Typography>
                    </Paper>
                </Grid>
                {/* Right hand content */}
                <Grid item xs={12} md={4} lg={3}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: 240,
                        }}
                    >
                        <Typography variant="subtitle1">
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
                        </Typography>
                        
                        <Typography variant="caption" display="block" sx={{marginTop: 'auto'}} gutterBottom>
                            Created {props.project.created}
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
            <Copyright sx={{pt: 4}}/>
        </Container>
    )
}

interface ImageTabProps {
    project: Project
}

function ImageTab(props: ImageTabProps) {
    return (
        <div>
            {props.project.resources.map(imgsrc => {
                return <img src={imgsrc.uri} alt={"A nice image."} width={'100%'}/>
            })}
        </div>
    )
}

function SettingsTab() {
    const style = {
        width: '100%',
        maxWidth: 360,
        bgcolor: 'background.paper',
    };

    
    return (
        <div>
            <Typography variant={"h4"} sx={style}>Settings</Typography>

            <Typography variant="subtitle1">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
            </Typography>
        </div>
    )
}