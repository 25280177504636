import axios from "axios";
import {LoginModel, SignUpModel, UserToken} from "../authentication/useAuth";

export async function login(user: LoginModel) : Promise<UserToken>  {
    const response = await axios.post<UserToken>("api/v1/auth/login", user);
    return response.data
}

export async function signup(user: SignUpModel) {
    const response = await axios.post<UserToken>("/api/v1/auth/signup", user);
    return response.data;
}