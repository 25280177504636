import {
    Box,
    CardActions,
    CardContent,
    Grid,
    Stack,
    Typography,
    CardMedia,
    Button,
    Container,
    Card,
    Link, GlobalStyles, CssBaseline
} from '@mui/material';
import React from 'react';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import {Link as RouterLink, Navigate} from 'react-router-dom'
import useAuth from "../authentication/useAuth";

export default function Home() {
    const {user} = useAuth()
    if(user) {
        return <Navigate to={"/dashboard"}></Navigate>
    }
    
    const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9];

    return (
        <div>
            <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
            <CssBaseline />
            
            <NavMenu></NavMenu>
            
            <Box
                sx={{
                    bgcolor: 'background.paper',
                    pt: 8,
                    pb: 6,
                }}
            >
                <Container maxWidth="md">
                    <Typography
                        component="h1"
                        variant="h2"
                        align="center"
                        color="text.primary"
                        gutterBottom
                        fontWeight={800}
                    >
                        Reference photos made easy
                    </Typography>
                    <Typography 
                        variant="h5" 
                        align="center" 
                        color="text.secondary"
                        fontWeight={300}
                        paragraph>
                        Join thousands of businesses and creators who trust us 
                        to upload and share their reference photos.
                    </Typography>
                    <Stack
                        sx={{pt: 4}}
                        direction="row"
                        spacing={2}
                        justifyContent="center"
                    >
                        <Link
                            to={"/signup"}
                            component={RouterLink}
                        >
                            <Button variant="outlined">Create account</Button>
                        </Link>

                        <Link
                            to={"/project"}
                            component={RouterLink}
                        >
                            <Button variant="contained">Upload your photos</Button>
                        </Link>
                    </Stack>
                </Container>
            </Box>
            <Container sx={{py: 8}} maxWidth="md">
                {/* End hero unit */}
                <Grid container spacing={4}>
                    {cards.map((card) => (
                        <Grid item key={card} xs={12} sm={6} md={4}>
                            <Card
                                sx={{height: '100%', display: 'flex', flexDirection: 'column'}}
                            >
                                <CardMedia
                                    component="img"
                                    sx={{
                                        // 16:9
                                        pt: '56.25%',
                                    }}
                                    image="https://source.unsplash.com/random"
                                    alt="random"
                                />
                                <CardContent sx={{flexGrow: 1}}>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Heading
                                    </Typography>
                                    <Typography>
                                        This is a media card. You can use this section to describe the
                                        content.
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button size="small">View</Button>
                                    <Button size="small">Edit</Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </div>
    );
}

const NavMenu = () => {
    return (
        <React.Fragment>
            <AppBar
                position="static"
                color="default"
                elevation={0}
                sx={{borderBottom: (theme) => `1px solid ${theme.palette.divider}`}}
            >
                <Toolbar sx={{flexWrap: 'wrap'}}>
                    <Typography variant="h6" color="inherit" noWrap sx={{flexGrow: 1}}>
                        Company name
                    </Typography>
                    <nav>
                        <Link
                            variant="button"
                            color="text.primary"
                            href="#"
                            sx={{my: 1, mx: 1.5}}
                        >
                            Features
                        </Link>
                        <Link
                            variant="button"
                            color="text.primary"
                            href="#"
                            sx={{my: 1, mx: 1.5}}
                        >
                            Enterprise
                        </Link>
                        <Link
                            variant="button"
                            color="text.primary"
                            href="#"
                            sx={{my: 1, mx: 1.5}}
                        >
                            Support
                        </Link>
                    </nav>
                    
                    <Button variant="outlined" component={RouterLink} to={"/login"}>
                        Login
                    </Button>
                </Toolbar>
            </AppBar>
        </React.Fragment>
    );
}