import React from 'react';
import NavMenu from './NavMenu';
import {Container} from "@mui/material";

export default function Layout(props) {
    return (
        <div>
            <NavMenu />
            <Container maxWidth={'xl'} disableGutters>
                {props.children}
            </Container>
        </div>
    );
}