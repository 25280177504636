import React, {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import {login, signup} from "../services/AuthService"
import axios from "axios";

interface AuthContextType {
    user?: UserToken;
    error?: any;
    logIn: (model: LoginModel) => Promise<UserToken>;
    signUp: (model: SignUpModel) => Promise<void>;
    logout: () => void;
}

const AuthContext = createContext<AuthContextType>(
    {} as AuthContextType
);

const user_storage_key = "user"

// Export the provider as we need to wrap the entire app with it
export function AuthProvider({children}: { children: ReactNode; }): JSX.Element {
    const [user, setUser] = useState<UserToken>();
    
    useEffect(() => {
        const storage = JSON.parse(localStorage.getItem(user_storage_key)) as UserToken
        
        if(storage) {
            console.log("Setting auth header to:", storage.token)
            axios.defaults.headers.common['Authorization'] = "Bearer " + storage.token;
        }
        
        setUser(storage)
    }, [])
    
    function logIn(model: LoginModel) {
        return new Promise((resolve, reject) => {
            login(model)
                .then((user) => {
                    setUser(user);
                    localStorage.setItem(user_storage_key, JSON.stringify(user))
                    if(user.token) {
                        console.log("Setting auth header to 1:", user.token)
                        axios.defaults.headers.common['Authorization'] = "Bearer " + user.token;
                    }
                    resolve(user)
                })
                .catch(err => {
                    reject(err)
                })
        })
    }
    
    function signUp(model: SignUpModel) {
        return new Promise((resolve, reject) => {
            signup(model)
                .then((user) => {
                    setUser(user);
                    resolve(user)
                })
                .catch(err =>{
                    reject(err)
                })
        });
    }
    
    function logout() {
        localStorage.setItem(user_storage_key, null)
        setUser(undefined)
    }

    // We only want to render the underlying app after we assert for the presence of a current user.
    return (
        <AuthContext.Provider value={{user, logIn, signUp, logout} as AuthContextType}>
            {children}
        </AuthContext.Provider>
    );
}

// Let's only export the `useAuth` hook instead of the context.
// We only want to use the hook directly and never the context component.
export default function useAuth() {
    return useContext(AuthContext);
}

export interface LoginModel {
    username: string
    password: string
    rememberMe: boolean
}

export interface SignUpModel {
    username: string
    email: string
    password: string
    rememberMe: boolean
}

export interface UserToken {
    token: string
    expires: Date
}
