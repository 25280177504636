import {Project} from "../../services/ProjectService";
import {Button, CardActions, CardContent, CardMedia, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {Link} from "react-router-dom";

function linkToProject(project: Project): string {
    return "/project/" + project.id
}

export default function ProjectGrid({project}: {project: Project}) {
    return (
        <Grid lg={4} md={6} sm={12} key={project.id} sx={{
            boxShadow: 2,
            borderRadius: 2
        }}>
            <CardMedia
                component="img"
                image={project.resources[0].uri}
                alt={"Image"}
                sx={{borderRadius: 2}}
            />
            <CardContent sx={{flexGrow: 1}}>
                <Typography gutterBottom variant="h5" component="h2">
                    {project.projectName}
                </Typography>
                <Typography>
                    {project.description}
                </Typography>
            </CardContent>
            <CardActions>
                {/*<Link component={Link} to={linkToProject(project)}>View</Link>*/}
                
                <Button size="small" component={Link} to={linkToProject(project)}>View</Button>
                <Button size="small" component={Link} to={linkToProject(project)}>Edit</Button>
            </CardActions>
        </Grid>
    )
}