import React from 'react';
import {Route, Routes} from 'react-router-dom';
import AppRoutes from './AppRoutes';
import './custom.css';
import {createTheme, CssBaseline, ThemeProvider} from "@mui/material";
import useAuth from "./authentication/useAuth";

const themeLight = createTheme({
    palette: {
        background: {
            default: "#fafafa"
        }
    }
});

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

export default function App() {

    const {user} = useAuth()

    return (
        <ThemeProvider theme={themeLight}>
            <Routes>
                {AppRoutes.map((route, index) => {
                    const {element, ...rest} = route;
                    return <Route key={index} {...rest} element={element}/>;
                })}
            </Routes>
        </ThemeProvider>
    );
}

App.displayName = App.name