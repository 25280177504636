import React, {useState} from "react";
import {
    Alert,
    Avatar,
    Box,
    Button, Checkbox,
    Container,
    CssBaseline,
    FormControlLabel,
    Grid, Snackbar,
    TextField,
    Typography
} from "@mui/material";
import {Copyright} from "@mui/icons-material";
import {Link, useNavigate} from "react-router-dom";
import useAuth, {SignUpModel} from "../../authentication/useAuth";

export default function SignUp() {

    const {signUp} = useAuth()
    const navigate = useNavigate()
    
    const [error, setError] = useState(false)
    const [formData, setFormData] = useState<SignUpModel>({
        username: "",
        email: "",
        password: "",
        rememberMe: false
    })
    
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        
        signUp(formData)
            .then(result => {
                setError(false)
                navigate("/login")
            })
            .catch(err => {
                console.log(err)
                setError(true)
            })
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                    {/*<LockOutlinedIcon/>*/}
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign up
                </Typography>
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 3}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                autoComplete="given-name"
                                name="userName"
                                required
                                fullWidth
                                id="userName"
                                label="Username"
                                value={formData.username}
                                onChange={(e) => setFormData({...formData, username: e.target.value})}
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                value={formData.email}
                                onChange={(e) => setFormData({...formData, email: e.target.value})}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="new-password"
                                value={formData.password}
                                onChange={(e) => setFormData({...formData, password: e.target.value})}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox 
                                    value="true" 
                                    color="primary"
                                    onChange={(e) => setFormData({...formData, rememberMe: e.target.checked})}
                                />}
                                label="Remember me"
                                value={formData.rememberMe}
                            />
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2}}
                    >
                        Sign Up
                    </Button>
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Link to={"/login"}>
                                Already have an account? Sign in
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Copyright sx={{mt: 5}}/>

            <Snackbar
                open={error}
                autoHideDuration={5000}
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            >
                <Alert
                    hidden={!error}
                    sx={{marginY: 2}}
                    severity={"error"}
                >
                    Sign up failed.
                </Alert>
            </Snackbar>
        </Container>
    );
}
