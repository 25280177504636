import React, {useState} from "react";
import {
    Alert, Box, Container, Paper, Snackbar, TextField, Typography
} from "@mui/material";
import { DropzoneArea } from "mui-file-dropzone";
import {LoadingButton} from "@mui/lab";
import {useNavigate} from "react-router-dom";
import {createProject, CreateProjectForm} from "../../services/ProjectService";

export interface ReferenceProjectProps {
    
}

export default function CreateProject(props: ReferenceProjectProps) {

    const [formData, setFormData] = useState<CreateProjectForm>({
        name: "",
        description: "",
        files: null
    })
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState(false)

    const navigate = useNavigate()

    const handleFileSubmit = (files) => {
        console.log(files)
        setFormData({
            ...formData,
            files: files
        })
    }

    function handleSubmit() {
        console.log("Handle submit")
        setSubmitting(true)
        setError(false)

        createProject(formData)
            .then(response => {
                setSubmitting(false)
                setError(true)
                console.log("Submitted.")

                navigate('/project/' + response.id)
            })
            .catch(err => {
                setSubmitting(false)
                setError(true)
                console.log(err)
            })
    }

    // Check if we're logged in. If not, redirect.
    //
    // if(submitting) {
    //     return (
    //         <h1>Submitting... Please wait.</h1>
    //     )
    // }

    return (
        <Container maxWidth="md">
            <Paper sx={{p: 4, mt: 5}} elevation={1}>
                <Typography
                    variant="h4"
                    component="h2"
                    align={"left"}
                    fontWeight={700}
                    fontSize={24}
                    sx={{marginBottom: 2}}
                >
                    Create a Project
                </Typography>

                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': {my: 1, width: '100%'},
                        '& .MuiBox-root': {my: 1, width: '100%'},
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <TextField
                        required
                        id="outlined-required"
                        label="Title"
                        defaultValue=""
                        onChange={event => setFormData({...formData, name: event.target.value})}
                        error={formData.name === ""}
                    />

                    <TextField
                        id="outlined-multiline-static"
                        label="Description"
                        rows={4}
                        defaultValue=""
                        onChange={event => setFormData({...formData, description: event.target.value})}
                        error={formData.description === ""}
                        multiline
                    />

                    <DropzoneArea
                        acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
                        onChange={handleFileSubmit}
                    />                    
                </Box>

                {/*<input type="file" onChange={handleFileSubmit} required/>*/}

                {/*<ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164}>*/}
                {/*    {formData.files?.map((image) => (*/}
                {/*        <ImageListItem key={image.name}>*/}
                {/*            <img*/}
                {/*                src={URL.createObjectURL(image)}*/}
                {/*                alt={image.name}*/}
                {/*                loading="lazy"*/}
                {/*            />*/}
                {/*        </ImageListItem>*/}
                {/*    ))}*/}
                {/*</ImageList>*/}

                <LoadingButton
                    variant="contained"
                    color="success"
                    type={"submit"}
                    onClick={handleSubmit}
                    loading={submitting}
                    sx={{marginTop: 2}}
                >Create Project</LoadingButton>

                <Snackbar
                    open={error}
                    autoHideDuration={5000}
                    anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                >
                    <Alert
                        hidden={!error}
                        sx={{marginY: 2}}
                        severity={"error"}
                    >
                        Failed to create project. Please make sure you attached your images.
                    </Alert>
                </Snackbar>
            </Paper>
        </Container>
    )
}