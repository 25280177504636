import useAuth from "../../authentication/useAuth";
import {useNavigate} from "react-router-dom";
import React from "react";

export default function SignOut() {
    
    const {logout} = useAuth();
    const navigate = useNavigate()
    
    logout()
    navigate("/")
    
    return (
        <h1>Logging out...</h1>
    );
}