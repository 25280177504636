import Home from "./components/Home";
import Dashboard from "./components/dashboard/Dashboard";
import Layout from "./components/Layout";
import ViewProject from "./components/Project/ViewProject";
import CreateProject from "./components/Project/CreateProject";
import SignUp from "./components/intake/Signup";
import SignIn from "./components/intake/Signin";
import React from "react";
import SignOut from "./components/intake/SignOut";
import AuthorizedRoute from "./components/AuthorizedRoute";
import ProjectList from "./components/Project/ProjectList";

const AppRoutes = [
    {
        index: true,
        element: <Home/>
    },
    {
        path: '/project',
        element: <Layout><ProjectList/></Layout>
    },
    {
        path: '/project/create',
        element: <AuthorizedRoute><Layout><CreateProject/></Layout></AuthorizedRoute>
    },
    {
        path: '/project/:id',
        element: <Layout><ViewProject/></Layout>
    },
    {
        path: '/dashboard',
        element: <AuthorizedRoute><Layout><Dashboard/></Layout></AuthorizedRoute>
    },
    {
        path: '/signup',
        element: <SignUp/>
    },
    {
        path: '/login',
        element: <SignIn/>
    },
    {
        path: '/logout',
        element: <SignOut/>
    }
];

export default AppRoutes;
