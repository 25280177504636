import axios from "axios";

export interface CreateProjectForm {
    name: string
    description: string
    files: FileList | null
}

export interface Project {
    id: string
    projectName: string
    description: string
    resources: Reference[]
    created: string
    author: string
}

export interface Reference {
    uri: string
}

export async function createProject(form: CreateProjectForm) : Promise<Project> {
    const formData = new FormData();
    formData.append('name', form.name)
    formData.append('description', form.description)

    for (let i = 0; i < form.files.length; i++) {
        formData.append('files', form.files[i]);
    }
    
    const response = await axios.post<Project>("api/v1/project", formData);
    return response.data
}

export async function getProjectById(id: string) : Promise<Project> {
    const response = await axios.get<Project>("api/v1/project/" + id);
    return response.data;
}

export async function getMyProjects() : Promise<Project[]> {
    const response = await axios.get<Project[]>("api/v1/project/me");
    return response.data;
}

export async function getAllProjects() : Promise<Project[]> {
    const response = await axios.get<Project[]>("api/v1/project");
    return response.data;
}