import React, {useState} from "react";
import {
    Alert,
    Avatar,
    Box,
    Checkbox,
    Container,
    CssBaseline,
    FormControlLabel, Grid, Snackbar,
    TextField,
    Typography
} from "@mui/material";
import {Copyright} from "@mui/icons-material";
import {Link, useNavigate} from "react-router-dom";
import {LoadingButton} from "@mui/lab";
import useAuth, {LoginModel} from "../../authentication/useAuth";

export default function SignIn() {

    const navigate = useNavigate()
    
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [formData, setFormData] = useState<LoginModel>({
        username: "",
        password: "",
        rememberMe: true
    })
    
    const {logIn} = useAuth()

    const handleSubmit = (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        
        setLoading(true)
        
        logIn(formData)
            .then(result => {
                setLoading(false)
                setError(false)
                navigate("/")
                console.log(result)
            })
            .catch(err => {
                setLoading(false)
                setError(true)
                console.log(err)
            })
    };

    return (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        {/*<LockOutlinedIcon />*/}
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <Box component="form" noValidate sx={{ mt: 1 }} autoComplete={"off"}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Username"
                            name="username"
                            autoComplete="username"
                            value={formData.username}
                            onChange={(e) => setFormData({...formData, username: e.target.value})}
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={formData.password}
                            onChange={(e) => setFormData({...formData, password: e.target.value})}
                        />
                        <FormControlLabel
                            control={<Checkbox 
                                color="primary"
                                value="rememberme"
                                checked={formData.rememberMe}
                                onChange={(e) => setFormData({...formData, rememberMe: e.target.checked})}
                            />}
                            label="Remember me"
                        />

                        <LoadingButton
                            variant="contained"
                            color="success"
                            type={"submit"}
                            onClick={(e) => handleSubmit(e)}
                            loading={loading}
                            sx={{mt: 3, mb: 2}}
                            fullWidth
                        >
                            Sign In
                        </LoadingButton>
                        
                        <Grid container>
                            <Grid item xs>
                                <Link to="/forgotpassword">
                                    Forgot password?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link to={"/signup"}>
                                    Don't have an account? Sign Up
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />

                <Snackbar
                    open={error}
                    autoHideDuration={5000}
                    anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                >
                    <Alert
                        hidden={!error}
                        sx={{marginY: 2}}
                        severity={"error"}
                    >
                        Login failed. Please check your credentials or create an account.
                    </Alert>
                </Snackbar>
            </Container>
    );
}